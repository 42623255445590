import { createBrowserHistory, Update } from "history";
import {
  ComponentProps,
  useCallback,
  useEffect,
  useState,
  useTransition,
} from "react";
import { Router } from "react-router-dom";

import { nprogress } from "./nprogress";

type State = Pick<ComponentProps<typeof Router>, "location" | "navigationType">;

function getStateFromUpdate(update: Update): State {
  return { location: update.location, navigationType: update.action };
}

// todo: do we want to take this a a prop and/or pass down through context?
const history = createBrowserHistory({ window });

// this reducer ignores previous state; it behaves like setState but with slightly cleaner syntax
const reducer = (state: unknown, action: Update): State =>
  getStateFromUpdate(action);

type SuspenseRouterProps = Omit<
  ComponentProps<typeof Router>,
  "navigator" | keyof State
>;

export default function SuspenseRouter(props: SuspenseRouterProps) {
  const [isPending, startTransition] = useTransition();
  const [state, setState] = useState(() => getStateFromUpdate(history));

  // for some reason startTransition doesn't work with useReducer so we simulate a reduce with setState
  const dispatch = useCallback(
    (action: Update) => setState((prev) => reducer(prev, action)),
    [],
  );

  useEffect(
    () => history.listen((update) => startTransition(() => dispatch(update))),
    [dispatch],
  );

  useEffect(() => {
    void nprogress.configure({ showSpinner: false });
    return () => nprogress.remove();
  }, []);

  useEffect(() => {
    if (isPending) nprogress.start();
    else nprogress.done();
  }, [isPending]);

  return <Router navigator={history} {...props} {...state} />;
}
